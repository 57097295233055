


























































import { Component, Vue, Watch } from "vue-property-decorator";
import Gravatar from "vue-gravatar";
import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import { getTopics } from "@/api";
import type { Topic, Board } from "@/api";
import NewTopic from "@/components/NewTopic.vue";
import store from "@/store";
import { decode } from "html-entities";
import type { MetaInfo } from "vue-meta";
import { DateTime } from "luxon";

@Component({
  components: {
    NewTopic,
    InfiniteLoading,
    Gravatar,
  },
  metaInfo(): MetaInfo {
    return {
      title: (this as TopicListPage).board.display_name,
    };
  },
})
export default class TopicListPage extends Vue {
  topics: Topic[] = [];
  busy = false;
  infiniteId = +new Date();

  get board(): Board {
    return store.state.board;
  }

  decodeTitle(x: string): string {
    return decode(x);
  }

  @Watch("$route.params")
  resetInfinite(): void {
    this.infiniteId = +new Date();
    this.topics = [];
  }

  async handleInfinite($state: StateChanger): Promise<void> {
    const limit = 10;
    const topics = await getTopics(this.board.id, this.topics.length, limit);

    if (topics.length) {
      this.topics.push(...topics);
    }
    if (topics.length < limit) {
      $state.complete();
    } else {
      $state.loaded();
    }
  }

  updatedAtString(topic: Topic): string {
    return `${DateTime.fromISO(topic.updated_at).toRelative()}에 업데이트됨`;
  }

  isUpdated(topic: Topic): boolean {
    const lastVisited = this.lastVisited(topic.id);
    return !!lastVisited && DateTime.fromISO(topic.updated_at) > lastVisited;
  }

  lastVisited(id: number): DateTime | null {
    const item = localStorage.getItem(`topic-last-visited.${id}`);

    if (item) {
      return DateTime.fromISO(item);
    } else {
      return null;
    }
  }

  mounted(): void {
    if (
      store.state.board.is_active == true &&
      store.state.user.isBlocked == false
    ) {
      store.commit("setCanWrite", true);
    }
  }

  checkCanWrite(tp: Topic): boolean {
    if (
      tp.is_closed == true ||
      tp.is_suspended == true ||
      tp.is_hidden == true ||
      store.state.user.isBlocked == true
    ) {
      return false;
    } else {
      return true;
    }
  }
}
